import React, { useState } from "react";
import { tourActivity, productData } from "../../mockData/mockData";
import ProductCard from "../ActivitiesCard/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';

const CityCard = ({ city }) => (
  <div className="tour-city-card">
    <img className="image-wrapper" src={city.url} alt={city.city} />
    <p className="tour-content">{city.city}</p>
  </div>
);

const TourPackage = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
  
      <><div className="city-tour-wrapper">
      <div className="tour-title">Dubai City Tour Packages</div>
      <img src="https://d1i3enf1i5tb1f.cloudfront.net/assets/Whitelabel/img/holidaylist-banner.png" alt="Dubai City Tour" />
    </div>
    <div className="outer-wrapper">
        <span className="tours-title">Top Dubai Tours & Activities</span>

        <div className="tour-cities-wrapper">
          <div className="tour-carousel-wrapper">
            {tourActivity.map((city) => (
              <CityCard key={city.id} city={city} />
            ))}
          </div>
        </div>

        <hr
          style={{
            height: "1px",
            color: "#e7e7e7",
            borderTop: "none",
            borderLeft: "none",
          }} />

        <div className="about-tour-wrapper">
          <span className="text-title">Best Tours and Activities in Dubai</span>
          <span>
            {isExpanded
              ? <p>We have got a whole bunch of perfect Dubai packages for you Dubai, best known for its architectural delights, also offers the most incredible family-friendly leisure choices, spectacular ever entertainment scene, and charming cultural historical attractions.</p>
              : <p>We have got a whole bunch of perfect Dubai packages for you Dubai, best known for its architectural delights, also offers the most incredible family-friendly leisure choices, spectacular ever entertainment scene, and charming cultural historical attractions.In fact, this Arabian city is a brilliant playground for all, with its endless sights and sounds. So whether you are here for exhilarating adventure or simply seeking a super relaxing getaway, get ready for an unmatched sensory overload with our exciting Dubai holiday choices.
                It may be a place to enjoy year-round, but there is no denying that it’s most delightful to visit Dubai during the winter months when the place is cool, salubrious and full of life, thanks to events such as DSF (Dubai Shopping Festival), Christmas and New Year’s Eve festivities to name a few. Add to this some amazing seasonal attractions including Global Village, Dubai Miracle Garden etc. From the Grand DSF Package and Dubai Winter Escape to the Ultimate Water Sports Package, we have dedicated Dubai tour packages to help you make the most of this time.
                Dubai is a favorite of newly-weds and families with little ones alike. While those romantic at heart can choose from our Honeymoon Special, Valentines’ Day Package, and Romantic Vacation, our family vacationers are in for a treat with our exclusive Perfect Family Break and Family Getaway. We have the most Dubai holiday packages for adventurists as well; Fun-filled Moments at Atlantis and 3 Nights Adventure are just a few to mention. What’s more? There are also holidays that venture further afield by way of City Delights of Dubai and Abu Dhabi.<br></br>
                <br></br>
                <strong>Get your Dubai holiday off to a perfect start with these irresistible packages</strong>
                <br />
                <li>4 Nights / 5 Days Magical Dubai</li>
                <li>4 Nights / 5 Days Quick Dubai Delights</li>
                <li>5 Nights / 6 Days Dubai Family Fun Package</li>
                <li>5 Nights / 6 Days Summer Family Fun in Dubai</li>
                <br></br>
                Don’t want to shell out a fortune for your Dubai travel? You can’t go wrong by choosing our value-packed Dubai tour packages like Budget Glimpse (packaged for four nights), Dubai Summer Escape, and Weekend Escape Dubai. Want to design a Dubai vacation themed around culture and cuisines? Just go forth and take your pick your pick from Flavours of Dubai, Culture Dubai, Arabian Nights Dubai and special Eid Celebrations Dubai. And for those looking to dive into Dubai’s unrivaled shopping scene, we have packages inclusive of shopping tours, mainly Magical Moments, Grand Tour Dubai etc.
                All our Dubai packages include airport transfers (both pick up and drop off), UAE 4G SIM Card, accommodation (in a hotel / resort of your choice), and Dubai visa (arranged upon your request). Dependent on your chosen holiday, this is further bundled with some of our best-selling tours (such as Dubai City Tour, Desert Safari and Dhow Cruise at Dubai Creek or Dubai Marina) plus entry tickets to some of the region’s most iconic sights and leisure attractions like Burj Khalifa, Dubai Frame, Dubai Parks and Resorts, and IMG Worlds of Adventure.
                Dubai, precisely, is a wonderful destination with the best of sea, sand, and sun! And Rayna Tours brings to you all of its best offerings in a single bouquet of its worthy packages. Now if you are looking for bespoke Dubai travel, let us know your unique holiday preferences and requirements needs; our travel specialists will customize and compile a holiday as per your exact needs.
                So don’t wait anymore; get your Dubai holiday off to a perfect start with these irresistible packages!
                <br></br>
                <br></br>
                <strong>Best Time To Visit Dubai</strong>
                <br></br>
                Weather-wise, the most ideal time to visit Dubai is from November to March when the mercury dips to an average of 20 degree Celsius, making it perfect to enjoy outdoor activities in a pleasant, sunny setting. It is also the time when Dubai becomes the most effervescent and vibrant with the events and seasonal attractions such as Dubai Shopping Festival, Global Village, and Dubai Miracle Garden to name a few.
                For maximum savings on your Dubai holiday, the best time to visit Dubai is during summer months. With temperatures rising up to 50 degree Celsius, it may be unbearably hot, but its intensely cool indoors and super chilly attractions (such as Ski Dubai, Dubai Ice Rink, and Chillout Lounge) make up for it. The added perk is that it lets you see the region’s iconic landmarks in its calmer, crowd-free version.
                <br></br>
                <br></br>
                <strong>
                  How To Reach Dubai</strong>
                <br></br>
                Dubai is an internationally famed city which sees an influx of over 16 million visitors every year. So it goes without saying that there are regular flights from different parts of the globe (almost on daily basis) to reach here. Unsurprisingly, Dubai International Airport is one of the world’s busiest airports. You can also enter Dubai via other airports or entry points across the UAE, such as Abu Dhabi, Ras Al Khaimah, and Sharjah.
                To visit Dubai or UAE via road from its neighboring countries including Oman, you must carry all your essential documents which ensure your smooth entry into Dubai. Now if you are traveling to Dubai from other emirates, there are affordable yet reliable bus services which transfer you to Dubai on-time, safely. You can also count on our convenient private transfers to reach Dubai.
                <br></br>
                <strong>Best Places To Visit In Dubai</strong>
                <br />
                <strong>
                  Burj Khalifa:</strong>
                <br />
                No introduction is probably required to describe this record-breaking attraction. At a height of about 828 meters, it is currently the world’s tallest man-made structure and undoubtedly one of Dubai’s greatest attractions. Get your admission tickets to its observatory decks on 124th or 148th level – this is one of the best ways to access its interiors. It not only gives you an unmatched overview of the city skyline but also comes with an incredibly swift elevator ride that journeys you to the viewing deck in less than a minute.
                <br />
                <strong>The Dubai Mall:</strong>
                Located adjacent to Burj Khalifa in Downtown Dubai, this is a must-see on any Dubai visit, being one of the world’s largest malls. Besides its over 1200 retail outlets and some 200 dining options, it is an absolute oasis for fun and leisure lovers of all age groups. There is the wondrous Dubai Aquarium and Underwater Zoo, one-of-its-kind Dubai Ice Rink, the largest-of-its-kind VR Park Dubai, and the kid-exclusive KidZania Dubai, among others. As you step out of the mall, be sure to catch with the strikingly choreographed Dubai Fountain show in perfect sync of music and light.
                <br />
                <strong>Palm Jumeirah:</strong>
                <br />
                This man-made island is another definite-see attraction in Dubai. Designed to resemble a vast palm tree topped by a crescent, it is an epicenter of fantastic sights and extraordinary experiences in the city. Stroll through the breathtaking Boardwalk, take a selfie against the imposing façade of Atlantis, The Palm Resort, have some amazing water fun at Aquaventure Water Park, and mostly watch the brand-new Palm Fountain show, which is the largest fountain in the world.
                <br />
                <strong>Dubai Parks and Resorts:</strong>
                <br />
                Theme park amusement and adventure can’t get any better than with a visit to the Middle East’s first as well as the largest integrated resort. After all, it hosts amazing four parks such as Hollywood-based Motiongate Dubai, Hindi-movie inspired Bollywood Parks Dubai, and LEGO-themed LEGOLAND Dubai plus LEGOLAND Water Park.
                <br />
                <strong>Bluewaters Island:</strong>
                <br />
                This waterfront destination is one of the region’s newest landmarks. However, it is more than an impressive spot filled with intricate architecture. It boasts of some of Dubai’s most astonishing and ground-breaking attractions, mainly Ain Dubai – the world’s highest Ferris wheel, Madame Tussauds Museum – the first of its kind in the Middle East, and 3D World Selfie Museum Dubai, which is the world’s largest 3D museum.
                <br></br>
                <strong>Al Bastakiya Quarter:</strong>
                <br></br>
                Dubai isn’t just a place to take in the towering skyscrapers and endless leisure attractions. It’s a favorite of culture and history buffs as well. For those looking for a step-back moment in Dubai, a must-visit is Al Bastakiya Quarter, also known as Al Fahidi Historical Neighborhood. This 19th century quarter is still preserved to its original glory, giving you an enlightening insight into the region’s lifestyle before the discovery of oil.
                Things To Do In Dubai
                <br />
                <strong>Dubai City Tour:</strong>
                <br />
                Whether you are a first timer or someone who don’t want to explore Dubai on your own, it is highly recommended to see this wonderful city on a guided sightseeing tour. For this, Rayna Tours has several options at your disposal; take a Big Bus Tour or opt for our city tour inclusive of both hotel pick up and drop off.
                <br />
                <strong>Dhow Cruise:</strong>
                <br />
                Looking for an alternative sightseeing experience in Dubai? Set out on an atmospheric cruise aboard a traditional dhow along Dubai Creek or Dubai Marina. This allows you to see Dubai’s breathtaking cityscape from an unmatched viewpoint on water. For a memorable evening in Dubai, choose from our diverse dhow cruise packages complete with buffet dining and live entertainment shows like Tanura.
                <br />
                <strong>Desert Safari:</strong>
                <br />
                A desert safari in Dubai introduces you to the region’s raw desert beauty and serenity which still remains unscathed by any kind of developments. With our vast repertoire of desert safari packages, you can make your desert safari as adventurous and action-packed or relaxed as possible. Take your pick from morning or evening desert safari with 4X4 dune bash, camel ride, BBQ dining, and traditional performances such as Belly Dance and Tanura. You can take your desert outing to the next level with an overnight desert safari.
                <br />

                <strong>Skiing at Ski Dubai:</strong>
                <br />
                Dubai is a land of unbelievable experiences! So, if you fancy a session of skiing or snow sledging in the desert city, it is effortlessly possible here – just head to Ski Dubai at Mall of the Emirates. This snow-themed destination is the Middle East’s first resort. Yes, it is a real wonderland where you get to enjoy your favorite winter pastimes year-round, even when the summer heat is at its peak.
                Capture a Pose Against Burj Al Arab:
                No Dubai trip is complete until you visit this iconic sail-shaped structure and at least click a picture against it. For those who don’t know, it is one of the most luxurious accommodations in the world. Set on its own private island, it is an all-suite resort.
                What To Eat In Dubai
                More than 200 nationalities call Dubai their second home means you can imagine the sheer diversity of its gastronomic scene. Apart from this, it is also the destination where stunning dining settings, scrumptious street food flavors, and insta-worthy delicacies overlap. Not to mention, be it spicy Indian, traditional English style breakfast or authentic Japanese Sushi, you get to relish or try whatever your taste bud craves for once in Dubai. While you enjoy your favorite cuisines, make sure that you don’t miss out to sample the traditional Arabic dishes, particularly Shawarma, Falafel with Hummus, Manakeesh, and Al Machboos. There are also a few local desserts that you should try, such as Luqaimat, Kunafah, Mehalabiya and Umm Ali.
                Shopping In Dubai
                Dubai is a one-stop shopping destination! Its mix of 70 plus giant malls and several traditional souks (Arabian bazaars), spread across Deira and Bur Dubai region, is a testament to this fact. So, whether you want to indulge in the up-to-date shopping trends or do super bargain shopping, Dubai caters to every pocket and preference. What’s more? Dubai is the venue of Dubai Shopping Festival (DSF) – an annual event that is dedicated to shopping, dining, leisure and entertainment. If you are a shopping freak, don’t miss to add this event (that falls in January – February) in your Dubai tour package; it lets you bag the latest as well as the most luxurious merchandise with up to 70% to 90% discount.
                Best Hotels In Dubai
                From over-the-top to the most affordable, Dubai has hotels to suit every budget and style. In fact, every accommodation in Dubai has its own character. For the comforts enhanced by epic grandeur, there are sure-fire choices like Atlantis, The Palm Resort, Burj Al Arab, Le Royal Meridian Beach Resort & Spa, and Jumeirah Dar Al Masyaf. If you are looking for a budget-friendly accommodation with decent comforts, there are endless (two-star and three-star) options in Deira and Bur Dubai areas.
                <br />
                <strong>How To Get Dubai Visa</strong>{" "}
                If you are not qualified for any kind of visa exemptions, you must mandatorily apply and get your Dubai visa approved, much prior to your intended travel date. But, if you find Dubai visa application procedures complicated, our visa specialists offer systematic guidance to help you pick the right Dubai visa while assuring you of its appropriate processing and prompt approval.</p>}
            <button onClick={toggleReadMore} className="read-more-btn">
              {isExpanded ? "  Read More..." : " Read Less..."}
            </button>
          </span>

        </div>
        <div className="tourActivity">
          {productData.map((travel, index) => (
            <ProductCard
              title={travel.title}
              description={travel.description}
              price={travel.price}
              imageUrl={travel.imageUrl}
              altText={travel.altText} />
          ))}

        </div>
      </div></>
  );
};

export default TourPackage;
