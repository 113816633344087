import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Styles/background.css";

const BackgroundCarousel = ({ backgroundImagesData }) => {
  return (
    <div className="slider-div">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showStatus={false}
        showThumbs={false}
        showArrows={false} // This removes the arrow buttons
        showIndicators={true} // This removes the indicator dots
      >
        {backgroundImagesData &&
          backgroundImagesData.map(({ id, url }) => (
            <div key={id} className="background-image-div">
              <img src={url} alt={`background-${id}`} />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default BackgroundCarousel;
