import React, { useState } from "react";
import "./style.scss";

const faqs = [
  {
    id: 1,
    question: "What safety measures are in place for tours?",
    answer:
      "We follow all local and international safety guidelines, provide sanitizers, ensure social distancing, and all our staff are trained in first aid.",
  },
  {
    id: 2,
    question: "What is the cancellation policy?",
    answer:
      "You can cancel your tour up to 48 hours in advance for a full refund. Cancellations made less than 48 hours in advance will incur a 50% fee.",
  },
  {
    id: 3,
    question: "Are meals included in the tour packages?",
    answer:
      "Most of our tour packages include meals. Please check the specific tour details for more information on what is included.",
  },
  {
    id: 4,
    question: "Do you offer group discounts?",
    answer:
      "Yes, we offer group discounts for groups of 10 or more people. Please contact our customer service for more details.",
  },
  {
    id: 5,
    question: "What should I pack for my tour?",
    answer:
      "We recommend packing comfortable clothing, a hat, sunscreen, a water bottle, and any personal medication you may need. Specific recommendations will be provided based on the tour.",
  },
];

function FAQs() {
  const [expanded, setExpanded] = useState(null);

  const toggleAccordion = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <div className="container">
      <h2>Frequently Asked Questions</h2>
      <div className="accordion">
        {faqs.map((faq) => (
          <div className="accordion-item" key={faq.id}>
            <button
              onClick={() => toggleAccordion(faq.id)}
              aria-expanded={expanded === faq.id}
              aria-controls={`faq-${faq.id}`}
            >
              <span className="accordion-title">{faq.question}</span>
              <span className="icon" aria-hidden="true"></span>
            </button>
            <div
              id={`faq-${faq.id}`}
              className="accordion-content"
              style={{
                opacity: expanded === faq.id ? 1 : 0,
                maxHeight: expanded === faq.id ? "9em" : "0",
              }}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
