import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Left, Right } from "./Arrows";
import { topCitiesData } from "../mockData/mockData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles/top-cities.css";

// const CityCard = ({ city }) => (
//   <div className="city-card">
//     <img src={city.url} alt={city.city} />
//     <p className="content">{city.city}</p>
//   </div>
// );

const CityCard = ({ city }) => (
  <div className="city-card">
    <Link to={`/cities/${city.route}`}>
      <img src={city.url} alt={city.city} />
      <p className="content">{city.city}</p>
    </Link>
  </div>
);

class TopCities extends Component {
  render() {
    return (
      <div>
        <div className="top-cities-wrapper">
          <h1>Top Cities on Headout</h1>
          <hr
            style={{
              backgroundColor: "#ffbb58",
              width: "75px",
              height: "2px",
              border: "none",
              marginTop: "0px",
              marginLeft: "0px",
              marginBottom: "20px",
            }}
          />
          <div className="carousel-wrapper ">
            {topCitiesData.map((city) => (
              <CityCard key={city.id} city={city} />
            ))}
          </div>
          <hr
            style={{
              height: "1px",
              color: "#e7e7e7",
              borderTop: "none",
              borderLeft: "none",
            }}
          />
        </div>
        <hr className="section-divide-hr" />
      </div>
    );
  }
}


export default TopCities;
