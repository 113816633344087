import React from 'react';
import './styles.scss'; // Import the CSS file

const ProductCard = ({ title, description, price, imageUrl, altText }) => {
  return (
    <div className="cont">
      <div className="product-card">
        <div className="product-card__image">
          <img src={imageUrl} alt={altText} />
        </div>
        <div className="product-card__info">
          <h2 className="product-card__title">{title}</h2>
          <p className="product-card__description">{description}</p>
          <div className="product-card__price-row">
            <span className="product-card__price">AED {price}</span>
            <button className="product-card__btn">Book Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
