export const topCitiesData = [
  {
    id: 3,
    city: "Dubai",
    route: "dubai",
    description: "Roam the eternal city",
    url:
      "https://www.travelandleisure.com/thmb/k53-FUyx7Uyg1CzpuTuMSZXvY64=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-dubai-DUBAITG1123-17390625954c4be3902a440d8fffde67.jpg",
  },
    {
      id: 1,
      city: "Abu Dhabi",
      route: "new-york",
      description: "Take a bite of the Big Apple",
      url:
        "https://cdn-images-rayna.imgix.net/imgiximages/city-images/13236/abu-dhabi.jpg",
    },
    {
      id: 2,
      city: "Ras al Khaimah",
      route: "coming-soon",
      description: "An offer you can't refuse",
      url:
        "https://cdn-images-rayna.imgix.net/imgiximages/city-images/14644/ras-al-khaimah-city.png",
    },
   
    {
      id: 4,
      city: "X Dubai",
      route: "paris",
      description: "C'est La Vie",
      url:
        "https://dubaitravelblog.com/wp-content/uploads/2018/08/wingsuits-dubai-marina-xdubai.jpg",
    },
    {
      id: 5,
      city: "Moon Resort",
      route: "london",
      description: "For everything hunky-dory",
      url:
        "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
    },
    {
      id: 6,
      city: "Dubai",
      route: "dubai",
      description: "An Oasis like no other",
      url:
        "https://cdn-imgix.headout.com/cities/dubai/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 7,
      city: "Barcelona",
      route: "barcelona",
      description: "Hacer Peunte a Catalunya",
      url:
        "https://cdn-imgix.headout.com/cities/barcelona/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 8,
      city: "Madrid",
      route: "madrid",
      description: "Discover the hear of Spain",
      url:
        "https://cdn-imgix.headout.com/cities/madrid/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 9,
      city: "Singapore",
      route: "singapore",
      description: "The Lion City",
      url:
        "https://cdn-imgix.headout.com/cities/singapore/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 10,
      city: "Venice",
      route: "venice",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/venice/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 11,
      city: "Milan",
      route: "milan",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/milan/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 12,
      city: "Naples",
      route: "naples",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/naples/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 13,
      city: "Budapest",
      route: "budapest",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/budapest/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 14,
      city: "Edinburg",
      route: "edinburg",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/edinburgh/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
    {
      id: 15,
      city: "Florence",
      route: "florence",
      description: "Enjoy and have fun in the City",
      url:
        "https://cdn-imgix.headout.com/cities/florence/images/mobile/morning.jpg?auto=compress&fm=webp&w=412.5&h=486&crop=faces&fit=min",
    },
  ];

  export const tourActivity = [
  
    {
      id: 1,
      city: "Dubai",
      route: "dubai",
      description: "Roam the eternal city",
      url:
        "https://www.travelandleisure.com/thmb/k53-FUyx7Uyg1CzpuTuMSZXvY64=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-dubai-DUBAITG1123-17390625954c4be3902a440d8fffde67.jpg",
    },
    {
      id: 2,
      city: "X Dubai",
      route: "paris",
      description: "C'est La Vie",
      url:
        "https://dubaitravelblog.com/wp-content/uploads/2018/08/wingsuits-dubai-marina-xdubai.jpg",
    },
    {
      id: 3,
      city: "Moon Resort",
      route: "london",
      description: "For everything hunky-dory",
      url:
        "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
    },
    {
        id: 3,
        city: "Helicopter Tours",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://d1i3enf1i5tb1f.cloudfront.net/CategoryType-Images/41790/helicopter-tour.jpg",
      }, {
        id: 3,
        city: "Moon Resort",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
      }, {
        id: 3,
        city: "Moon Resort",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
      },
      {
        id: 3,
        city: "Moon Resort",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
      }, {
        id: 3,
        city: "Moon Resort",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
      }, {
        id: 3,
        city: "Moon Resort",
        route: "london",
        description: "For everything hunky-dory",
        url:
          "https://media.architecturaldigest.com/photos/631f7f63f9b23c63c2313f54/4:3/w_1896,h_1422,c_limit/Dubai%20Pearl%207_Final%20(002).jpg",
      },

  ];

  export const tourCards = [
    {
      id: 1,
      currentPrice: 29,
      currency: "AED",
      stars: 4.6,
      ratings: 681,
      city: "Dubai",
      description: "Dubai Frame",
      url:
        "https://images.adsttc.com/media/images/5a4b/bce6/b22e/38b5/fa00/0067/newsletter/Screen_Shot_2018-01-02_at_12.05.08_PM.jpg?1514912994",
    },
    {
      id: 2,
      currentPrice: 57.5,
      currency: "AED",
      stars: 4.6,
      ratings: 564,
      cashback: 10,
      city: "Dubai",
      description: "X Line Dubai Marina",
      url:
        "https://getawaystravelandtourism.com/wp-content/uploads/2020/06/67053709_2587024768008526_3019762785371291648_n.jpg",
    },
    {
      id: 3,
      currentPrice: 40.5,
      lastPrice: 79,
      currency: "$",
      discount: 49,
      city: "Dubai",
      description: "Skydive Dubai",
      url:
        "https://www.visitdubai.com/-/media/gathercontent/poi/s/skydive-dubai/fallback-image/sky-dive-dubai-3.jpg",
    },
    {
      id: 4,
      currentPrice: 141,
      lastPrice: 146,
      currency: "AED",
      discount: 16,
      stars: 4.6,
      ratings: 2588,
      cashback: 5,
      city: "Dubai",
      description: "Burj Khalifa: At the Top",
      url:
        "https://cdn-imgix.headout.com/tour/2636/TOUR-IMAGE/84609881-4697-4b73-bb46-9998b2fd7aa2-1866-dubai-burj-khalifa-at-the-top-01-4-.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min",
    },
    {
      id: 5,
      currentPrice: 196,
      lastPrice: 206,
      currency: "AED",
      discount: 8,
      stars: 4.6,
      ratings: 1240,
      cashback: 5,
      city: "Dubai",
      description: "Dubai Acquarium & Underwater Zoo",
      url:
        "https://media.tacdn.com/media/attractions-splice-spp-674x446/0e/b4/eb/f4.jpg",
    },
    {
      id: 6,
      currentPrice: 20,
      currency: "AED",
      stars: 4.6,
      ratings: 437,
      city: "Dubai",
      description: "Emirates Park Zoo",
      url:
        "https://www.emiratesparkzooandresort.com/media/experience_giraffe@2x.jpg",
    },
    {
      id: 7,
      currentPrice: 31,
      lastPrice: 45,
      currency: "€",
      discount: 31,
      stars: 4.6,
      ratings: 474,
      cashback: 10,
      city: "PARIS",
      description: "Skip The Line: Eiffel Tower Tickets with Host",
      url:
        "https://cdn-imgix.headout.com/tour/8092/TOUR-IMAGE/d9ee5fc2-5c9e-4981-8f4a-d16dc69769fd-P1.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min",
    },
  ];

  export const travelData = [
    {
      category: "ADVENTURE",
      topic: "Yosemite National Park & Adventure Games (Guided)",
      score: 4.3,
      reviews: 371,
      discount: 30,
      originalPrice: 213,
      imgSrc: "https://cache-graphicslib.viator.com/graphicslib/page-images/742x525/467829_Viator_Unsplash_170410.jpg"
    },
    {
      category: "LEISURE",
      topic: "Relaxing Beach Resort in Bali",
      score: 4.7,
      reviews: 289,
      discount: 15,
      originalPrice: 150,
      imgSrc: "https://example.com/bali-resort.jpg"
    }
  ];

  export  const productData = [
    {
      title: "IMG Worlds of Adventure",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 365.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/false-4753/33.jpg",
      altText: "Red Nike Shoes"
    },
    {
      title: "Dubai Aquarium",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 175.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/Dubai-Aquarium-and-Underwater-Zoo-3636/1725255249463_S.jpg",
      altText: "Red Nike Shoes"
    },
    {
      title: "AYA Universe Dubai",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 125.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/AYA-Universe-Dubai-508739/1725544690551_S.jpg",
      altText: "Red Nike Shoes"
    }
    ,
    {
      title: "Dubai",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 125.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/Dubai-City-Tour-33/1724924851484_S.jpg",
      altText: "Red Nike Shoes"
    },
    {
      title: "Skydive Dubai",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 2661.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/Skydive-Dubai-8904/1725277925702_S.jpg",
      altText: "Red Nike Shoes"
    },
    {
      title: "Ski Dubai",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 125.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/Ski-Dubai-Tickets-172/1724910161350_S.jpg",
      altText: "Red Nike Shoes"
    },
    {
      title: "Dubai Dolphinarium",
      description: "Experience ultimate comfort and style with these iconic Nike Air Max sneakers.",
      price: 50.00,
      imageUrl: "https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/Final/Dubai-Dolphinarium-65/1725256331675_S.jpg",
      altText: "Red Nike Shoes"
    }
  ]